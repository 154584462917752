import React, { useEffect, useState } from "react";
import "./Playlists.css"




export default function Playlists() {


    return (
        <div className='main_window_container'>
        <h1>THIS IS THE PLAYLIST COMPONENT</h1>
        </div>
    )

    }
